//font sizes
$xs-font: 11px;
$sm-font: 13px;
$md-font: 15px;
$lg-font: 21px;
$xl-font: 79px;
$sub-heading-font: 33px;

//paddings
$sm-pad: 2.5px;
$md-pad: 8.5px;
$lg-pad: 16.5px;
$xl-pad: 19.5px;

//margins
$sm-marg: 2.5px;
$md-marg: 8.5px;
$lg-marg: 16.5px;
$xl-marg: 30.5px;
$xxl-marg: 40px;

//border radius
$b-radius: 18px;
$sm-b-radius: 5px;

//colors
$dark: #242424;
$blue: #4d7bb7;
$half-white: #dadada;
$light-dark: #303030;
$light-blue: #3d68a02f;

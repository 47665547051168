.appbar {
	@include flex-between;
	@include container;
	max-width: 1600px;
	margin-top: 5px;

	h2 {
		cursor: pointer;
		transition: 0.2s;
		color: $blue;
		font-weight: 500;
		padding: $sm-pad 0;
		@include button;
		color: $blue;
		border: 0;
		// font-size: 17.5px;
		&:hover {
			color: $half-white;
		}
	}
	a {
		text-decoration: none;
	}
}

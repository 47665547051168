.intro {
	@include flex-center;
	@include container;
	max-width: 1130px;
	align-items: flex-start;
	flex-direction: column;
	height: 100vh;
	// margin-top: 800px 0;
	transform: translateY(-50px);
	animation: opacity-fade-anim 2.4s forwards;
	h1 {
		font-size: $xl-font;
		color: $blue;
	}
	h2 {
		font-size: $xl-font;
		color: $blue;
		opacity: 0.6;
	}
	p:nth-child(1) {
		font-size: $sm-font;
	}
	p {
		max-width: 520px;
		font-size: 14.5px;
		line-height: 1.3;
		text-align: justify;
		margin: $sm-marg 0;
	}
	a {
		@include button;
		margin: $lg-marg 0;
		text-decoration: none;
	}

	// socials
	.socials {
		@include flex-center;
		justify-content: space-around;
		max-width: 300px;
		margin: 10px auto;
		a {
			border: none;
			background: none;
		}

		.instagram-svg {
			transition: 0.2s;
			&:hover {
				cursor: pointer;
				transform: translateY(-5px);
				path {
					fill: $blue;
				}
			}
		}
		.twitter-svg {
			transition: 0.2s;
			&:hover {
				cursor: pointer;
				transform: translateY(-5px);
				path {
					fill: $blue;
				}
			}
		}
		.codesandbox-svg {
			transition: 0.2s;
			&:hover {
				cursor: pointer;
				transform: translateY(-5px);
				path {
					fill: $blue;
				}
			}
		}
		.linkedin-svg {
			transition: 0.2s;
			&:hover {
				cursor: pointer;
				transform: translateY(-5px);
				path {
					fill: $blue;
				}
			}
		}

		.github-svg {
			transition: 0.2s;
			width: 32px;
			height: 33px;
			&:hover {
				cursor: pointer;
				transform: translateY(-5px);
				path {
					fill: $blue;
				}
			}
		}
	}

	// footer
	// .footer {
	// 	max-width: 170px;
	// 	margin: 10px auto;
	// 	text-align: center;
	// 	a {
	// 		background: none;
	// 		border: none;
	// 		text-decoration: none;
	// 		margin-top: 30px;
	// 		font-size: $sm-font;
	// 		color: $blue;
	// 		letter-spacing: 1px;
	// 		line-height: 15px;
	// 		cursor: pointer;
	// 		&:hover {
	// 			opacity: 0.6;
	// 		}
	// 	}
	// }

	@include smallerThan(1050px) {
		h1,
		h2 {
			font-size: 90px;
		}
	}

	@include smallerThan(800px) {
		h1,
		h2 {
			font-size: 70px;
		}
	}

	@include smallerThan(720px) {
		h1,
		h2 {
			font-size: 50px;
		}
		p {
			padding: 10px 0;
		}
	}

	@include smallerThan(550px) {
		h1,
		h2 {
			font-size: 35px;
		}
		p {
			font-size: $sm-font;
		}
	}
	//media query
	@include smallerThan(380px) {
		h1,
		h2 {
			font-size: 29px;
		}
	}
	@include smallerThan(320px) {
		h1,
		h2 {
			font-size: 25px;
		}
	}
	@include smallerThan(280px) {
		h1,
		h2 {
			font-size: 22px;
		}
	}

	@keyframes opacity-fade-anim {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
}

.container {
	@include container;
}

.center {
	@include flex-center;
	height: 100vh;
}

.logo-wrapper {
	border: 2.5px solid $dark;
	display: inline-block;
	border-radius: 7px;
	animation: wrap 0.5s linear forwards 2.5s;
	.logo-paths {
		margin: -3.5px 6px;
		margin-bottom: -7px;
	}
	path {
		fill: none;
		stroke: $blue;
		stroke-width: 2;
		stroke-dasharray: 530;
		stroke-linecap: round;
		animation: paths 4.5s linear forwards;
	}
}

.logo-not-loading-wrapper {
	border: 2px solid $blue;
	display: inline-block;
	border-radius: $sm-b-radius;
	.logo-paths {
		margin: -4.5px 6px;
		margin-bottom: -7px;
	}
	path {
		fill: $blue;
	}
}

@keyframes paths {
	from {
		stroke-dashoffset: 530;
	}
	to {
		stroke-dashoffset: 0;
		fill: $blue;
	}
}

@keyframes wrap {
	to {
		border-color: $blue;
	}
}

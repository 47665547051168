.findme {
	cursor: default;

	.socials {
		@include flex-center;
		justify-content: space-around;
		max-width: 300px;
		margin: 0 auto;

		.instagram-svg {
			transition: 0.2s;
			&:hover {
				cursor: pointer;
				transform: translateY(-5px);
				path {
					fill: $blue;
				}
			}
		}
		.twitter-svg {
			transition: 0.2s;
			&:hover {
				cursor: pointer;
				transform: translateY(-5px);
				path {
					fill: $blue;
				}
			}
		}
		.codesandbox-svg {
			transition: 0.2s;
			&:hover {
				cursor: pointer;
				transform: translateY(-5px);
				path {
					fill: $blue;
				}
			}
		}
		.linkedin-svg {
			transition: 0.2s;
			&:hover {
				cursor: pointer;
				transform: translateY(-5px);
				path {
					fill: $blue;
				}
			}
		}

		.github-svg {
			transition: 0.2s;
			width: 32px;
			height: 33px;
			&:hover {
				cursor: pointer;
				transform: translateY(-5px);
				path {
					fill: $blue;
				}
			}
		}
	}

	//media query
	@include smallerThan(500px) {
		padding: 1rem;
	}
}

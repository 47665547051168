.tabs {
	.tabs-items {
		list-style-type: none;
		display: flex;
		max-width: 500px;
		margin: $xl-marg auto;
		justify-content: center;
		li {
			@include button;
			white-space: nowrap;
			border: 0;
			font-weight: 400;
			font-size: $md-font;
			margin-right: 3px;
		}
		overflow-x: auto;
		&::-webkit-scrollbar {
			display: none;
		}
		.active-tab {
			@include button;
			border: 0;
			font-weight: 400;
			font-size: $md-font;
			background-color: $light-blue;
		}
	}

	.content {
		@include container;
		opacity: 0;
		max-width: 700px;
		margin: $xl-marg auto;
		margin-top: 0;
		justify-content: flex-start;
		@include card;
		&:hover {
			transform: none;
		}
		h3 {
			font-weight: 500;
			margin-bottom: $sm-marg;
			color: $blue;
			font-size: $lg-font;
			margin-top: 0;
		}
		a {
			color: $half-white;
			margin-left: 10px;
			font-size: $lg-font;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		p {
			margin-bottom: 50px;
			font-style: italic;
			opacity: 0.8;
			font-size: $sm-font;
		}
		.content-learned-items {
			margin-top: 40px;
			ul {
				list-style-type: none;
				li {
					font-size: 14px;
					@include grid;
					grid-template-columns: 0.5fr 15fr;
					place-items: start;
					letter-spacing: 1.1px;
					margin: 12px 0;
					text-align: justify;
					.suqaredbullet-svg {
						margin-top: 4.5px;
					}
				}
			}
		}
	}

	//single tab
	.single-tab {
		h3 {
			span {
				a {
					font-size: $md-font;
				}
			}
		}
	}
}

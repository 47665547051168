.experience {
	@include container;
	margin: $xl-marg auto;
	h2 {
		font-size: $sub-heading-font;
		margin-bottom: -3px;
		color: $blue;
		text-align: center;
	}
}
